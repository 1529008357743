import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styles from './index.module.css'
import Carousel from 'nuka-carousel';
import JSONData from "../../content/home-content.json"
export default function Home() {
  useEffect(() => {
    try {
      // 组件挂载时，创建script标签
      const umScript = document.createElement('script');
      // 设置标签的src属性
      umScript.text = "(function(w, d, s, q, i) {\n" +
        "  w[q] = w[q] || [];\n" +
        "  var f = d.getElementsByTagName(s)[0],j = d.createElement(s);\n" +
        "  j.async = true;\n" +
        "  j.id = 'beacon-aplus';\n" +
        "  j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js';\n" +
        "  f.parentNode.insertBefore(j, f);\n" +
        "})(window, document, 'script', 'aplus_queue', '203467608');\n" +
        "\n" +
        "const { aplus_queue } = window;\n" +
        "//集成应用的appKey\n" +
        "aplus_queue.push({\n" +
        "  action: 'aplus.setMetaInfo',\n" +
        "  arguments: ['appKey', '63b50875ba6a5259c4e089b7']\n" +
        "});\n" +
        "/************************以下内容为可选配置内容****************************/\n" +
        "//sdk提供手动pv发送机制，启用手动pv(即关闭自动pv)，需设置aplus-waiting=MAN;\n" +
        "//注意：由于单页面路由改变时不会刷新页面，无法自动发送pv，所以对于单页应用，强烈建议您关闭自动PV, 手动控制PV事件\n" +
        "aplus_queue.push({\n" +
        "  action: 'aplus.setMetaInfo',\n" +
        "  arguments: ['aplus-waiting', 'MAN']\n" +
        "});\n" +
        "//是否开启调试模式\n" +
        "aplus_queue.push({\n" +
        "  action: 'aplus.setMetaInfo',\n" +
        "  arguments: ['DEBUG', false]\n" +
        "});\n";
      // 明确设置为同步加载
      umScript.async = false;
      // 追加到body标签的最下面
      document.body.appendChild(umScript);
    } catch (e) {
      console.log('友盟脚本添加失败', e.message)
    }
  }, []);

  const [selectIndex, setIndex] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(0);
  try {
    document.title = 'ecool衣科'
  } catch {}
  return <div className={styles.container}>
    <img style={{width:'100%', height: '420px'}} src='./banner.jpg' className={styles.banner}/>
    <div className={styles.content}>
      <div className={styles.contentTitle}>衣科下载站点</div>
      <div className={styles.contentItem}>
        {JSONData.content.map((data, index) => {
          return <div className={styles.contentItemTitle} style={{color:index === selectIndex ? 'white':'#999999', backgroundColor:index === selectIndex ? '#FF5500' :""}} onClick={() => {
            setIndex(index)
          }}>{data.title}</div>
        })}
      </div>
      <div className={styles.subItemContainer}>
      {JSONData.content[selectIndex].items.map((item, index) => {
          let icons = null
          if (Array.isArray(item.icon)) {
            icons = <Carousel autoplay={true} autoplayInterval ={2000}
            defaultControlsConfig={{
              prevButtonStyle:{display:'none'},
              nextButtonStyle: {display: 'none'}
             }}
            style={{width:'100px', height:'100px', borderRadius:'8px'}}
            afterSlide={(index) => {
              if (index === 2) {
                setTimeout(()=>{
                  setSliderIndex(0)
                },1000)
              } else {
                setSliderIndex(index)
              }
            }}
            slideIndex={sliderIndex}
            >
              { item.icon.map((image, index)=>{
               return <img className={styles.icon} src={image} />
              })}
            </Carousel>
          } else {
            icons = <img src={item.icon} className={styles.icon}></img>
          }
          return <div className={styles.subItemContentContainer}>
            <div className={styles.subItemContentTop}>
              {icons}
              <div className={styles.subItemContentTopTitle}>{item.title}</div>
              {item.desc && (
                <div className={styles.subItemContentTopDesc}>{item.desc}</div>
              )}
            </div>
            <div className={styles.subItemContentBottomContainer}>
              {item.actions.map((action, index) => {
                if (action.type === 'download') {
                  return <div className={styles.subItemContentBottomDownload} onClick={() => { umengUp(action.umengKey) }}>
                    <a  className={styles.a} href={action.value}>{action.text}</a>
                    {/* <img className={styles.arrow} src='./arrow.png'></img> */}
                  </div>
                } else if (action.type === 'navigation') {
                  return <div className={styles.subItemContentBottomDownload} onClick={() => { umengUp(action.umengKey) }}>
                    <Link to="/download/"state={{ url:action.value, name:item.title, desc: item.desc }}>
                      <div className={styles.a}>{action.text}</div>
                      {/* <img className={styles.arrow} src='./arrow.png'></img> */}
                    </Link>
                  </div>
                } else {
                  return <div className={styles.subItemContentBottomDownload} onClick={() => { umengUp(action.umengKey) }}>
                    <a  className={styles.a} href={action.value}>{action.text}</a>
                    {/* <img className={styles.arrow} src='./arrow.png'></img> */}
                </div>
                }
              })}
            </div>
          </div>
        })}
      </div>
      <div className={styles.footer}>
        <div>注意：在线安装后如果出现两个打印程序，请删除原来老版本！如果安装出现问题，请致电400-677-0909转2</div>
        <div>
          <a  href='https://beian.miit.gov.cn' target="view_window">浙ICP备10201938号</a>
          <span>&nbsp;&nbsp;</span>
          杭州衣科信息技术股份有限公司 4006770909.com 版权所有
        </div>

      </div>
    </div>
  </div>
}

const umengUp = (eventCode) => {
  try {
    if (!eventCode) {
      return
    }
    const { aplus_queue } = window;
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [eventCode, 'CLK']
    });
  } catch (e) {
    console.log('友盟上报失败', e.message)
  }
}
// function isMobile () {
//   if (navigator && navigator.userAgent) {
//     if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
//       return true
//     } else {
//       return false
//     }
//   }
//   return false
// }
